body.teal {
  --bg: #87d0c6;
  --text: #040707;
}

body.purple {
  --bg: #573494;
  --text: #ffffff;
}

body.pink {
  --bg: #f48d99;
  --text: #040707;
}

body.lightGreen {
  --bg: #acd088;
  --text: #040707;
}

body.darkGreen {
  --bg: #0e7b3d;
  --text: #ffffff;
}

body.blue {
  --bg: #3bafe1;
  --text: #040707;
}

body.black {
  --bg: #040707;
  --text: #ffffff;
}

@keyframes marquee {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: -470px 0;
  }
}

@keyframes marquee2 {
  0% {
    background-position: 117px 0;
  }
  100% {
    background-position: -353px 0;
  }
}

.success-check {
  flex: 1;
  height: 55%;
  max-height: 340px;
  min-height: 300px;
  overflow: hidden;
  border-bottom: 1px solid var(--text);
  background-color: var(--bg);
  color: var(--text);
  position: relative;

  &__rows {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  &__row {
    width: 100%;
    height: 42px;
    background-image: url("/assets/pattern.svg");
    background-repeat: repeat-x;
    will-change: background-position;
    border-bottom: 1px solid var(--text);

    .black &,
    .purple &,
    .darkGreen & {
      background-image: url("/assets/pattern-white.svg");
    }

    &:nth-of-type(odd) {
      animation: marquee 15s infinite linear;
    }

    &:nth-of-type(even) {
      animation: marquee2 15s infinite linear;
    }
  }

  &__check {
    position: absolute;
    top: 20px;
    left: 50%;
    max-width: none;
    transform: translateX(-40%);
  }
}

.success-info {
  flex: 1;
  display: flex;
  padding: 24px 0;

  .width {
    display: flex;
    flex-direction: column;
  }

  h2 {
    font-weight: 400;
    font-size: 18px;
    margin-bottom: 10px;
    font-family: $font2;
  }

  h3 {
    font-size: 30px;
    margin-bottom: 20px;
  }

  .button {
    margin-top: auto;
  }
}
