@keyframes ringAnimation {
  0% {
    width: 25%;
    height: 25%;
    opacity: 0;
  }
  20% {
    opacity: 0;
    width: 40%;
    height: 40%;
  }
  30% {
    opacity: 1;
    width: 50%;
    height: 50%;
  }
  75% {
    width: 80%;
    height: 80%;
    opacity: 1;
  }
  100% {
    width: 100%;
    height: 100%;
    opacity: 0;
  }
}

.ready {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;

  &__icon {
    width: 120px;
    height: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }

  &__icon-ring {
    border: 1px solid $black;
    border-radius: 50%;
    width: 30%;
    height: 30%;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: ringAnimation 3s infinite linear;
  }

  &__icon-ring:nth-of-type(2) {
    animation-delay: 1s;
  }

  &__icon-ring:nth-of-type(3) {
    animation-delay: 2s;
  }

  &__scan {
    font-size: 12px;
    margin-top: 10px;
    text-align: center;
    display: block;
  }
}
