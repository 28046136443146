.fail {
  display: flex;
  flex: 1;
  align-items: center;

  img {
    margin-bottom: 20px;
  }

  h2 {
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 5px;
    font-family: $font2;
  }

  h3 {
    font-size: 30px;
  }
}
