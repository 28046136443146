@font-face {
  font-family: "PP Neue Machina";
  src: url("/assets/subset-PPNeueMachina-Ultrabold.woff2") format("woff2"),
    url("/assets/subset-PPNeueMachina-Ultrabold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "PP Neue Machina";
  src: url("/assets/subset-PPNeueMachina-Regular.woff2") format("woff2"),
    url("/assets/subset-PPNeueMachina-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "PP Neue Machina";
  src: url("/assets/subset-PPNeueMachina-Light.woff2") format("woff2"),
    url("/assets/subset-PPNeueMachina-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
