// Colors
$white: #fcfcfc;
$black: #040707;
$blackHover: #262626;

// fonts
$font: "PP Neue Machina", sans-serif;
$font2: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
  Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;

// Breakpoints
$tinybp: 374px;
$smallbp: 500px;
$smbp: 640px;
$mediumbp: 800px;
$largebp: 960px;
$xlargebp: 1100px;

// Transitions
$fadeOffTransition: left 0s 0.15s, opacity 0.15s;
$fadeOnTransition: left 0s, opacity 0.15s;
