.header {
  background: white;
  height: 64px;
  min-height: 64px;
  border-bottom: 1px solid black;
  display: flex;
  align-items: center;

  img {
    transform: translateY(-3px);
  }
}
