.body {
  flex: 1;
  display: flex;
  flex-direction: column;
  background: $white;
  display: none;

  &--active {
    display: flex;
  }

  &--failed {
    background: #db6c6c;
  }

  &--invalid {
    background: #d6d6d6;
  }
}
