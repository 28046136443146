.arx-bar {
  display: flex;
  align-items: center;
  gap: 12px;
  border-top: 1px solid $black;
  height: 40px;
  padding: 0 24px;

  span {
    flex: 1;
    text-align: right;
    font-size: 12px;
    font-weight: 700;
    color: $black;
    font-family: $font2;
  }
}
