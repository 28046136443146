.button {
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 51px;
  width: 100%;
  color: $white;
  background: $black;
  border-radius: 4px;
  font-weight: bold;
  text-transform: uppercase;
  gap: 8px;
  transition: 0.2s background-color;

  &:hover {
    background-color: $blackHover;
    cursor: pointer;
  }
}
